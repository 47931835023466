<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item style="cursor: pointer" @click="$router.push({ name: 'tickets' })">
                    Liste des tickets
                </vk-breadcrumb-item>
                <vk-breadcrumb-item style="cursor: pointer" @click="$router.push({ name: 'mission_orders' })">
                    Liste des ordres de mission
                </vk-breadcrumb-item>
                <vk-breadcrumb-item
                    v-if="missionOrder.ticket.id"
                    style="cursor: pointer"
                    @click="$router.push({ name: 'ticket_view', params: { ticketId: missionOrder.ticket.id } })"
                >
                    Ticket {{ missionOrder.ticket.id }}
                </vk-breadcrumb-item>
                <vk-breadcrumb-item :disabled="true">
                    Ordre de mission {{ $route.params.missionOrderId }}
                </vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <div class="d-flex align-items-center" v-if="missionOrderIsUpdating">
                <strong>Chargement en cours de l'ordre de mission {{ $route.params.missionOrderId }}...</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
            <MissionOrder :mission-order="missionOrder" v-else />
        </div>
    </div>
</template>

<script>
import MissionOrder from '@/components/MissionOrder'
import store from '@/store'
import router from '@/router'
import { mapState } from 'vuex'

const enterUpdateGuard = (to, from, next) => {
    if (
        store.state.login.user.userData.accountType === 'workerAccount' &&
        store.state.login.user.userData.roles.includes('admin')
    ) {
        store.dispatch('users/updateUsersList')
    }

    store.dispatch('missionOrders/setCurrentMissionOrder', to.params.missionOrderId).catch(() => {
        store.dispatch('notifications/addNotification', {
            status: 'warning',
            message: "Échec de chargement de l'ordre de mission",
        })
        router.push({ name: 'mission_orders' })
    })

    next()
}

export default {
    name: 'MissionOrderView',
    components: { MissionOrder },
    data: () => ({}),
    computed: {
        ...mapState('missionOrders', {
            missionOrder: 'currentMissionOrder',
            missionOrderIsUpdating: 'currentMissionOrderUpdateIsPending',
        }),
    },
    beforeRouteEnter: enterUpdateGuard,
    beforeRouteUpdate: enterUpdateGuard,
    beforeRouteLeave(to, from, next) {
        store.dispatch('missionOrders/reset')
        next()
    },
}
</script>

<style scoped></style>
